import { LoginComponent, type IResponse } from '@ntpkunity/controls-ums'
import { useTheme } from "@mui/material";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EMAIL, ISTENANT, SETTINGS, SIGNUP_STEPS } from 'constants/localstorage';
import { setItembyKey } from 'helper/localstorage';
import { useEffect, useState } from "react";
import { FormWrap } from "./login-form.style";
import { useStoreContext } from '@store/storeContext';


export const LoginForm = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    actions: { setToast }
  } = useStoreContext()
  const redirectUri = searchParams.get('return_uri');
  const [logInResponse, setLoginResponse] = useState<IResponse>()
  const ilayoutText = {
    title: 'Hello, ready to dive in? ',
    subTitle: '🚀'
  }

  useEffect(() => {
    if (logInResponse) {
      const { data: settings, message } = logInResponse
      if (settings) {
        const {
          signup_steps: signupSteps,
          is_tenant: isTenant
        } = settings
        const transformedResponse = {
          access_token: settings.access_token,
          company_name: settings?.tenant_profile?.name,
          email: settings.user_name,
          is_logged_in: true,
          product_id: settings.allowed_products[0]?.id,
          refresh_token: settings.refresh_token,
          role: {
            is_admin: settings.roles?.[0]?.is_admin,
            name: settings.roles?.[0]?.name
          },
          stipe_user_created: false,
          tenant_id: settings.tenant_id,
          user_name: settings.profile.first_name
        };
        setItembyKey(SIGNUP_STEPS, signupSteps?.toLowerCase())
        setItembyKey(ISTENANT, JSON.stringify(isTenant))
        setItembyKey(EMAIL, settings.user_name)
        setItembyKey(SETTINGS, transformedResponse)
        if (redirectUri) {
          navigate(`/${redirectUri}`, {
            replace: true,
            state: {
              settings,
              isTenant,
              signupSteps
            }
          })
        }
      }
      else {
        setToast({
          toastMessage: "Enter a vaild password" || message,
          toastState: true,
          variant: 'error'
        })
      }
    }
  }, [logInResponse, redirectUri])

  useEffect(() => {
    const settings = localStorage.getItem(SETTINGS)

    if (settings) {
      const {
        signup_steps: signupSteps,
        is_tenant: isTenant
      } = JSON.parse(settings)
      navigate(`/${redirectUri}`, {
        replace: true,
        state: {
          settings,
          isTenant,
          signupSteps
        }
      })
    }
  }, []);

  return (
    <FormWrap theme={theme} className="form-wrap">
      <LoginComponent theme={theme} layoutText={ilayoutText} setResponse={setLoginResponse} />
    </FormWrap>
  );
};
