import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const FormWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.form-wrap": {
    form: {
      '.fp-link': {
        display: 'none'
      },
      '.MuiButtonBase-root': {
        '&.MuiButton-textPrimary': {
          marginTop: '24px',
        } 
      }
    }
  },
}));
