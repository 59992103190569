import { createTheme } from "@mui/material";
import { unityTheme } from "@ntpkunity/controls";

export const lightTheme = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: { main: "#FF8C32", contrastText: "#06113C" },
    secondary: { main: "#DDDDDD" },
  },
});
