import { Theme } from "@mui/system";
import { IToast } from "../shared/interfaces";

export default interface IActions {
  payload: unknown;
  type: string;
}

export interface IThemeConfig {
  theme: Theme;
}

export interface IBrowserStorage {
  localStorage: any;
  sessionStorage: any;
}

// TODO: add relevant types
export type StoreContextType = {
  actions: {
    setTheme: (data: "default" | "dark") => unknown;
    setSessionStorage: (data: string) => void;
    setLocalStorage: (data: string) => void;
    setToast: (data: IToast) => void;
  };
  states: {
    theme: IThemeConfig | null;
    localStorage: any;
    sessionStorage: any;
    toastData: IToast;
  };
};

// TODO: add relevant default values
export const defaultStoreValues = {
  states: {
    theme: null,
    sessionStorage: null,
    localStorage: null,
    inventoryData: { pageNum: 0, pageSize: 0, result: [], totalResults: 0 },
    toastData: {
      toastMessage: "",
      toastState: false,
      toastLength: undefined,
      variant: undefined,
    },
  },
  actions: {
    setTheme: (): void => undefined,
    setSessionStorage: (): void => undefined,
    setLocalStorage: (): void => undefined,
    setAssetInventoryInitialData: (): void => undefined,
    updateInventoryRow: (): void => undefined,
    setToast: (): void => undefined,
  },
};
