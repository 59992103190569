import { useReducer } from "react";
import themeReducer, {
  initialState as themeInitialState,
  updateThemeConfig,
} from "./ducks/theme";
import storageReducer, {
  initialState as storageInitialState,
  updateLocalStorage,
  updateSessionStorage,
} from "./ducks/browserStorage";
import { StoreContextType } from "./configs";
import { IToast } from "../shared/interfaces";

import ToastReducer, {
  initialState as toastInitialState,
  setToast,
} from "./ducks/Toast";

const useReducerActions = (): StoreContextType => {
  const [theme, themeDispatch] = useReducer(themeReducer, themeInitialState);
  const [browserStorage, stroageDispatch] = useReducer(
    storageReducer,
    storageInitialState
  );


  const [toastData, toastDispatch] = useReducer(
    ToastReducer,
    toastInitialState
  );
  const actions = {
    setTheme: (data: "default" | "dark") => {
      updateThemeConfig(data, themeDispatch);
    },
    setLocalStorage: (data: string) =>
      updateLocalStorage(data, stroageDispatch),
    setSessionStorage: (data: string) =>
      updateSessionStorage(data, stroageDispatch),
    setToast: (data: IToast) => setToast(data, toastDispatch),
  };

  return {
    actions,
    states: {
      theme,
      localStorage: browserStorage.localStorage,
      sessionStorage: browserStorage.sessionStorage,
      toastData: toastData,
    },
  };
};

export default useReducerActions;
