import "./root.component.css";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { Slide, StyledEngineProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import StoreProvider, { useStoreContext } from "../src/store/storeContext";
import { ModalProvider } from "react-modal-hook";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Snackbar, unityTheme } from "@ntpkunity/controls";
import { LoginValidatorComponent } from '@ntpkunity/controls-ums'
import PrivateRouter from "@router/privateRoutes";

export default function Root(props: any) {
  const queryClient = new QueryClient();

  return (
    <StoreProvider>
      <QueryClientProvider client={queryClient}>
        <LoginValidatorComponent isPublic={true}>
          <App {...props} />
        </LoginValidatorComponent>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </StoreProvider>
  );
}

const App = (_props: any) => {
  const { states, actions } = useStoreContext();
  const { toastData } = states;
  const onClose = () => {
    actions.setToast({ toastMessage: "", toastState: false });
  };

  return (
    <>
      <Snackbar
        theme={unityTheme}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={toastData?.toastLength ? toastData.toastLength : 5000}
        open={toastData?.toastState ? toastData?.toastState : false}
        onClose={onClose}
        message={toastData?.toastMessage ? toastData?.toastMessage : ""}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
      ></Snackbar>

      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={states.theme?.theme as any}>
          <ModalProvider>
            <BrowserRouter>
              <Router />
              {/* <LoginValidatorComponent>
                <PrivateRouter />
              </LoginValidatorComponent> */}
            </BrowserRouter>
          </ModalProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </>
  );
};
