import { FC} from "react";
import { AuthLayout } from '@layout/authentication';
import { LoginForm } from '@shared/components';
import SideBarImage from '../shared/assets/images/login-img.jpg'
import logo from '../shared/assets/images/unity-logo.svg'

const Login: FC = () => {
  return (
    <AuthLayout
      sideBarImage={SideBarImage}
      logoImage={logo}
    >
      <LoginForm />
    </AuthLayout>
  );
};

export default Login;
