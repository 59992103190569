import { useTheme } from "@mui/material";
import { AuthLayoutWrap } from "./auth-layout.style";
import { ReactNode } from "react";
import { Box, Typography } from "@ntpkunity/controls";

export const AuthLayout = ({
  children,
  logoImage,
  sideBarImage,
}: {
  children?: ReactNode;
  logoImage?: string;
  sideBarImage?: string;
}) => {
  const theme = useTheme();

  return (
    <>
      <AuthLayoutWrap theme={theme} className="auth-layout-wrap">
        <Box theme={theme} className="auth-left-img">
          <img src={sideBarImage} alt="login" />
        </Box>
        <Box theme={theme} className="auth-right-panel">
          <Box theme={theme} className="right-content">
            <Box theme={theme} className="logo-area">
              <img src={logoImage} alt="Logo" />
            </Box>
            <Box theme={theme} className="auth-form">
              {children}
            </Box>
            <Box theme={theme} className="auth-footer">
              <Typography className="text-muted" theme={theme} variant="caption" component="small" display={"block"}>
                Support: 0-000-000-0000 |{" "}
                <a className="link text-muted" href="mailto:sample@sample.com">
                  sample@sample.com
                </a>
              </Typography>
              <Typography className="text-muted" theme={theme} variant="caption" component="small" display={"block"}>
                Weekdays 6am-6pm PST - Weekends 10am-6pm PST
              </Typography>
            </Box>
          </Box>
        </Box>
      </AuthLayoutWrap>
    </>
  );
};
